<template>
  <div v-if="loading">
    <Loader />
    <TableSkelton />
  </div>
  <div v-else>
    <div class="pb-28">
      <TabTable
        title="Unit Pricing"
        :columns="unitPricingTab.unitPricing.columns"
        :rowFields="[{ id: 0, name: 'Line item' }]"
        :rowsData="tablesData.unitPricingData.data.map((row, index) => ({ ...row, index }))"
        :showSave="tablesData.unitPricingData.savePayload.length !== 0"
        :showTotals="true"
        :totals="unitPricingTotals"
        :calculationsLoading="calculationsLoading"
        @add-rows="handleAddRow"
        @edit-row="handleEditRow"
        @remove-row="handleConfirmRemoveRow"
        @cancel-add-row="handleCancelAddRow"
        @save-data="handleSubmit"
        @update-data="updateData"
        name="unitPricingData"
      />
    </div>
    <DeleteRowModal
      :showModal="deleteModal.open"
      :title="deleteModal.title"
      @close-modal="deleteModal.open = false"
      @delete-row="handleRemoveRow"
    />
    <Toast v-if="toast.showToast" :title="toast.title" />
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useQuery } from '@vue/apollo-composable'

import { unitPricingTab } from '@/keys/constants'
import TabTable from '@/components/layout/StripingTabTable.vue'
import Toast from '@/components/layout/Toast.vue'
import DeleteRowModal from '@/components/modals/DeleteRowModal.vue'
import Loader from '@/components/layout/Loader.vue'
import TableSkelton from '@/components/layout/TableSkelton.vue'
import { useDeleteTableData } from '@/composables/useDeleteTableData'
import { useEditTableData } from '@/composables/useEditTableData'
import { useAddRowData } from '@/composables/useAddRowData'
import { useUpdateRowData } from '@/composables/useUpdateRowData'
import GET_PROPOSAL_BY_ID from '@/graphql/queries/getProposalById.gql'

const route = useRoute()
const proposalByIdQuery = useQuery(GET_PROPOSAL_BY_ID, {
  id: route.params.id,
})

const tablesData = ref({
  unitPricingData: {
    data: [],
    savePayload: [],
  },
})
const deleteModal = ref({ open: false, title: '' })
const deleteRowData = ref({ name: null, data: null })
const toast = ref({ showToast: false, title: '' })
const calculationsLoading = ref(false)

const loading = computed(() => {
  return proposalByIdQuery.loading.value
})

const unitPricingTotals = computed(() =>
  proposalByIdQuery.result.value.proposal.unitPricings.nodes.reduce(
    (sum, option) => sum + option.total,
    0
  )
)

watch(proposalByIdQuery.result, () => {
  tablesData.value.unitPricingData.data = proposalByIdQuery.result.value.proposal.unitPricings.nodes
})

const handleAddRow = (name, rows) => {
  tablesData.value[name].data = [
    ...tablesData.value[name].data,
    ...rows.map((row, index) => ({
      ...row,
      index: tablesData.value[name].data.length + index,
    })),
  ]
}

const handleCancelAddRow = (name, rowId, rows) => {
  tablesData.value[name].data = rows
  tablesData.value[name].savePayload = tablesData.value[name].savePayload.filter(
    (item) => item.id !== rowId
  )
}

const handleEditRow = async (name, data, payload) => {
  let { responseMessage } = await useEditTableData(name, payload, data)
  await proposalByIdQuery.refetch()
  toast.value = { showToast: responseMessage.value.showToast, title: responseMessage.value.title }
}

const handleConfirmRemoveRow = (name, title, row) => {
  deleteModal.value.open = true
  deleteRowData.value = { name: name, data: row }
  deleteModal.value.title = title
}

const handleRemoveRow = async () => {
  let { responseMessage } = await useDeleteTableData(
    deleteRowData.value.name,
    deleteRowData.value.data.id,
    deleteRowData.value
  )
  await proposalByIdQuery.refetch()
  deleteModal.value = { open: false, title: '' }
  toast.value = { showToast: responseMessage.value.showToast, title: responseMessage.value.title }
}

const handleSubmit = async (name, newRow) => {
  let { responseMessage } = await useAddRowData(
    name,
    newRow,
    null,
    tablesData,
    null,
    route.params.id
  )
  tablesData.value[name] = { data: [], savePayload: [] }
  await proposalByIdQuery.refetch()
  toast.value = { showToast: responseMessage.value.showToast, title: responseMessage.value.title }
}

const updateData = async (name, row, isEditing) => {
  calculationsLoading.value = true
  try {
    await useUpdateRowData(name, row, isEditing, tablesData)

    const itemIndex = row.value.index
    if (tablesData.value[name].data[itemIndex]) {
      tablesData.value[name].data[itemIndex] = {
        ...tablesData.value[name].data[itemIndex],
        ...row.value,
      }
    }
  } finally {
    calculationsLoading.value = false
  }
}
</script>
