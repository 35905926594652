<template>
  <div v-if="loading">
    <Loader />
    <TableSkelton />
    <TableSkelton />
    <TableSkelton />
    <TableSkelton />
    <TableSkelton />
  </div>
  <div v-else>
    <div class="flex justify-end mt-12">
      <BaseButton type="button" @click="showModal = true" label="Add Crew" size="small" />
    </div>
    <AddCrewModal
      serviceName="Concrete"
      :showModal="showModal"
      @close-modal="showModal = false"
      @submit="handleCrewSubmit"
    />
    <div>
      <HaulAndDumpTabTable
        name="haulAndDumpData"
        title="Haul & Dump Calculator"
        :columns="gradingDemoTab.haulDumpCalculator.columns"
        :rowFields="haulAndDumpDataQuery.items.value"
        :rowFieldsLoading="haulAndDumpDataQuery.loading.value"
        :rowsData="tablesData.haulAndDumpData.data"
        :showSave="tablesData.haulAndDumpData.savePayload.length !== 0"
        @add-rows="handleAddRow"
        @edit-row="handleEditRow"
        @remove-row="handleConfirmRemoveRow"
        @cancel-add-row="handleCancelAddRow"
        @save-data="handleSubmit"
        @update-data="updateData"
        @loadMoreData="haulAndDumpDataQuery.loadMore"
        @filterFieldsData="(input) => tableFilterFieldsHandler(input, haulAndDumpDataQuery)"
      />
    </div>

    <div>
      <ConstructionMaterials
        name="constructionMaterialsData"
        title="Concrete"
        :rowFields="constructionWorkQuery.items.value.filter((item) => item.material)"
        :rowFieldsLoading="constructionWorkQuery.loading.value"
        :rowsData="tablesData.constructionMaterialsData.data"
        @add-row="handleSubmit"
        @edit-row="handleEditRow"
        @remove-row="handleConfirmRemoveRow"
        @cost-updated="toast = { showToast: true, title: 'Cost Per Unit updated Successfully!' }"
        @loadMoreData="constructionWorkQuery.loadMore"
        @filterFieldsData="(input) => tableFilterFieldsHandler(input, constructionWorkQuery)"
      />
    </div>
    <div>
      <TabTable
        name="gradingEquipmentData"
        title="Grading Equipment"
        :columns="tabColumns().gradingDemoEquipment.columns"
        :rowFields="gradingEquipmentQuery.items.value"
        :rowFieldsLoading="gradingEquipmentQuery.loading.value"
        :rowsData="tablesData.gradingEquipmentData.data"
        :showSave="tablesData.gradingEquipmentData.savePayload.length !== 0"
        :calculationsLoading="calculationsLoading"
        @add-rows="handleAddRow"
        @edit-row="handleEditRow"
        @remove-row="handleConfirmRemoveRow"
        @cancel-add-row="handleCancelAddRow"
        @save-data="handleSubmit"
        @update-data="updateData"
        @handle-bulk-edit="handleEditRow"
        @handle-bulk-delete="handleConfirmRemoveRow"
        @loadMoreData="gradingEquipmentQuery.loadMore"
        @filterFieldsData="(input) => tableFilterFieldsHandler(input, gradingEquipmentQuery)"
      />
    </div>
    <div>
      <TabTable
        name="gradingLaborerData"
        title="Grading Labor"
        :columns="tabColumns(props.proposalData.proposal.publicWorks).labor.columns"
        :rowFields="gradingLaborQuery.items.value"
        :rowFieldsLoading="gradingLaborQuery.loading.value"
        :rowsData="tablesData.gradingLaborerData.data"
        :showSave="tablesData.gradingLaborerData.savePayload.length !== 0"
        :calculationsLoading="calculationsLoading"
        @add-rows="handleAddRow"
        @edit-row="handleEditRow"
        @remove-row="handleConfirmRemoveRow"
        @cancel-add-row="handleCancelAddRow"
        @save-data="handleSubmit"
        @update-data="updateData"
        @handle-bulk-edit="handleEditRow"
        @handle-bulk-delete="handleConfirmRemoveRow"
        @loadMoreData="gradingLaborQuery.loadMore"
        @filterFieldsData="(input) => tableFilterFieldsHandler(input, gradingLaborQuery)"
      />
    </div>
    <div>
      <TabTable
        name="equipmentData"
        title="Concrete Equipment"
        :columns="tabColumns().equipment.columns"
        :rowFields="equipmentQuery.items.value"
        :rowFieldsLoading="equipmentQuery.loading.value"
        :rowsData="tablesData.equipmentData.data"
        :showSave="tablesData.equipmentData.savePayload.length !== 0"
        :calculationsLoading="calculationsLoading"
        @add-rows="handleAddRow"
        @edit-row="handleEditRow"
        @remove-row="handleConfirmRemoveRow"
        @cancel-add-row="handleCancelAddRow"
        @save-data="handleSubmit"
        @update-data="updateData"
        @handle-bulk-edit="handleEditRow"
        @handle-bulk-delete="handleConfirmRemoveRow"
        @loadMoreData="equipmentQuery.loadMore"
        @filterFieldsData="(input) => tableFilterFieldsHandler(input, equipmentQuery)"
      />
    </div>

    <div>
      <TabTable
        name="laborerData"
        title="Concrete Labor"
        :columns="tabColumns(props.proposalData.proposal.publicWorks).labor.columns"
        :rowFields="laborQuery.items.value"
        :rowFieldsLoading="laborQuery.loading.value"
        :rowsData="tablesData.laborerData.data"
        :showSave="tablesData.laborerData.savePayload.length !== 0"
        :calculationsLoading="calculationsLoading"
        @add-rows="handleAddRow"
        @edit-row="handleEditRow"
        @remove-row="handleConfirmRemoveRow"
        @cancel-add-row="handleCancelAddRow"
        @save-data="handleSubmit"
        @update-data="updateData"
        @handle-bulk-edit="handleEditRow"
        @handle-bulk-delete="handleConfirmRemoveRow"
        @loadMoreData="laborQuery.loadMore"
        @filterFieldsData="(input) => tableFilterFieldsHandler(input, laborQuery)"
      />
    </div>
    <div>
      <TabTable
        name="miscellaneousCostData"
        title="Concrete Miscellaneous Cost"
        :columns="tabColumns().miscellaneousCost.columns"
        :rowFields="miscellaneousQuery.items.value"
        :rowFieldsLoading="miscellaneousQuery.loading.value"
        :rowsData="tablesData.miscellaneousCostData.data"
        :showSave="tablesData.miscellaneousCostData.savePayload.length !== 0"
        :calculationsLoading="calculationsLoading"
        @add-rows="handleAddRow"
        @edit-row="handleEditRow"
        @remove-row="handleConfirmRemoveRow"
        @cancel-add-row="handleCancelAddRow"
        @save-data="handleSubmit"
        @update-data="updateData"
        @handle-bulk-edit="handleEditRow"
        @handle-bulk-delete="handleConfirmRemoveRow"
        @loadMoreData="miscellaneousQuery.loadMore"
        @filterFieldsData="(input) => tableFilterFieldsHandler(input, miscellaneousQuery)"
      />
    </div>
    <DeleteRowModal
      :showModal="deleteModal.open"
      :title="deleteModal.title"
      @close-modal="deleteModal.open = false"
      @delete-row="handleRemoveRow"
    />
    <Toast v-if="toast.showToast" :title="toast.title" />
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue'
import { useQuery, useMutation } from '@vue/apollo-composable'
import { useRoute } from 'vue-router'

import { tabColumns, gradingDemoTab } from '@/keys/constants'
import Loader from '@/components/layout/Loader.vue'
import TabTable from '@/components/layout/TabTable.vue'
import Toast from '@/components/layout/Toast.vue'
import DeleteRowModal from '@/components/modals/DeleteRowModal.vue'
import ConstructionMaterials from '@/components/layout/ConstructionMaterials.vue'
import HaulAndDumpTabTable from '@/components/layout/HaulAndDumpTabTable.vue'
import TableSkelton from '@/components/layout/TableSkelton.vue'
import AddCrewModal from '@/components/layout/AddCrewModal.vue'
import BaseButton from '@/components/layout/BaseButton.vue'
import { useDeleteTableData } from '@/composables/useDeleteTableData'
import { useEditTableData } from '@/composables/useEditTableData'
import { useUpdateRowData } from '@/composables/useUpdateRowData'
import { useAddRowData } from '@/composables/useAddRowData'
import { usePaginatedQuery } from '@/composables/usePaginatedQuery'
import {
  filterGradingItems,
  filterServiceItems,
  filterConstructionMaterialsItems,
  tableFilterFieldsHandler,
} from '@/utils/utility_methods'

import GET_PROPOSAL_BY_ID from '@/graphql/queries/getProposalById.gql'
import GET_PROPOSAL_SERVICE from '@/graphql/queries/getProposalService.gql'
import GET_GRADING_EQUIPMENT_DATA from '@/graphql/queries/getGradingEquipment.gql'
import GET_GRADING_LABOR_DATA from '@/graphql/queries/getGradingLabor.gql'
import GET_CONCRETE_EQUIPMENT_DATA from '@/graphql/queries/getConcreteEquipment.gql'
import GET_CONCRETE_LABOR_DATA from '@/graphql/queries/getConcreteLabor.gql'
import GET_CONCRETE_MISCELLANEOUS_DATA from '@/graphql/queries/getConcreteMiscellaneous.gql'
import GET_CONCRETE_WORK_DATA from '@/graphql/queries/getConcreteConstructionWork.gql'
import GET_HAUL_AND_DUMP_FIELD_DATA from '@/graphql/queries/getHaulAndDumpFieldData.gql'
import ADD_PROPOSAL_CREW from '@/graphql/mutations/proposalAddCrew.gql'

const props = defineProps({
  proposalData: {
    type: Object,
  },
  proposalServices: {
    type: Object,
  },
})
const emit = defineEmits(['disableFinalbidTab'])

const route = useRoute()
const calculationsLoading = ref(false)
const showModal = ref(false)
const selectedCrew = ref(null)
const toast = ref({ showToast: false, title: '' })

const tablesData = ref({
  laborerData: { data: [], savePayload: [] },
  equipmentData: { data: [], savePayload: [] },
  miscellaneousCostData: { data: [], savePayload: [] },
  constructionMaterialsData: { data: [], savePayload: [] },
  gradingEquipmentData: { data: [], savePayload: [] },
  gradingLaborerData: { data: [], savePayload: [] },
  haulAndDumpData: { data: [], savePayload: [] },
})
const deleteModal = ref({ open: false, title: '' })
const deleteRowData = ref({ name: null, data: null, ids: [] })
const concreteServiceId = ref(null)
const gradingServiceId = ref(null)

const proposalByIdQuery = useQuery(GET_PROPOSAL_BY_ID, {
  id: route.params.id,
})
const constructionWorkQuery = usePaginatedQuery(GET_CONCRETE_WORK_DATA, 'concreteConstructionWork')
const gradingEquipmentQuery = usePaginatedQuery(GET_GRADING_EQUIPMENT_DATA, 'gradingEquipment')
const gradingLaborQuery = usePaginatedQuery(GET_GRADING_LABOR_DATA, 'gradingLaborers')
const equipmentQuery = usePaginatedQuery(GET_CONCRETE_EQUIPMENT_DATA, 'concreteEquipment')
const laborQuery = usePaginatedQuery(GET_CONCRETE_LABOR_DATA, 'concreteLaborers')
const miscellaneousQuery = usePaginatedQuery(
  GET_CONCRETE_MISCELLANEOUS_DATA,
  'concreteMiscellaneousCost'
)
const haulAndDumpDataQuery = usePaginatedQuery(GET_HAUL_AND_DUMP_FIELD_DATA, 'haulAndDump', {
  serviceName: 'concrete',
})
const { result } = useQuery(GET_PROPOSAL_SERVICE, {
  id: route.params.id,
})
const proposalAddCrew = useMutation(ADD_PROPOSAL_CREW)

const loading = computed(() => {
  return proposalByIdQuery.loading.value
})

const disableFinalBidTab = computed(() =>
  tablesData.value.constructionMaterialsData.data.some((item) => !item.isComplete)
)

watch(tablesData.value, (value) => {
  emit('disableFinalbidTab', disableFinalBidTab.value)
})

watch(
  () => props.proposalServices,
  (value) => {
    if (value?.proposalsService?.nodes) {
      let gradingService = value.proposalsService.nodes.find(
        (item) => item.service.name == 'Grading'
      )
      let concreteService = value.proposalsService.nodes.find(
        (item) => item.service.name == 'Concrete'
      )
      concreteServiceId.value = concreteService.id
      gradingServiceId.value = gradingService.id
    }
  },
  { immediate: true }
)

watch([concreteServiceId, proposalByIdQuery.result], () => {
  if (concreteServiceId.value && proposalByIdQuery.result.value) {
    tablesData.value.gradingLaborerData.data = filterGradingItems(
      proposalByIdQuery.result.value.proposal.laborers.nodes,
      concreteServiceId.value
    )
    tablesData.value.gradingEquipmentData.data = filterGradingItems(
      proposalByIdQuery.result.value.proposal.equipments.nodes,
      concreteServiceId.value
    )
    tablesData.value.laborerData.data = filterServiceItems(
      proposalByIdQuery.result.value.proposal.laborers.nodes,
      concreteServiceId.value
    )
    tablesData.value.equipmentData.data = filterServiceItems(
      proposalByIdQuery.result.value.proposal.equipments.nodes,
      concreteServiceId.value
    )
    tablesData.value.miscellaneousCostData.data = filterServiceItems(
      proposalByIdQuery.result.value.proposal.miscellaneousCosts.nodes,
      concreteServiceId.value
    )
    tablesData.value.constructionMaterialsData.data = filterConstructionMaterialsItems(
      proposalByIdQuery.result.value.proposal.constructionMaterial.nodes,
      concreteServiceId.value
    )

    tablesData.value.haulAndDumpData.data =
      proposalByIdQuery.result.value.proposal.lchWorks.nodes.filter((item) =>
        item.proposalsServices.nodes.find((service) => service.id === concreteServiceId.value)
      )
  }
})

const handleAddRow = (name, rows) => {
  tablesData.value[name].data = [...tablesData.value[name].data, ...rows]
}

const handleCancelAddRow = (name, rowId, rows) => {
  tablesData.value[name].data = rows
  tablesData.value[name].savePayload = tablesData.value[name].savePayload.filter(
    (item) => item.id !== rowId
  )
}

const handleEditRow = async (name, payload, row) => {
  let { responseMessage } = await useEditTableData(name, payload, row)
  proposalByIdQuery.refetch()
  toast.value = { showToast: responseMessage.value.showToast, title: responseMessage.value.title }
}

const handleConfirmRemoveRow = (name, title, rows) => {
  deleteModal.value.open = true
  deleteRowData.value = {
    name: name,
    data: rows,
    ids:
      name !== 'constructionMaterialsData' && name !== 'haulAndDumpData'
        ? rows.map((item) => item.id ?? item)
        : [],
  }
  deleteModal.value.title = title
}

const handleRemoveRow = async () => {
  let { responseMessage } = await useDeleteTableData(
    deleteRowData.value.name,
    deleteRowData.value.name !== 'haulAndDumpData' &&
      deleteRowData.value.name !== 'constructionMaterialsData'
      ? deleteRowData.value.ids
      : deleteRowData.value.data.id,
    deleteRowData.value.data.proposalsWorkProperties
  )
  proposalByIdQuery.refetch()
  deleteModal.value = { open: false, title: '' }
  toast.value = { showToast: responseMessage.value.showToast, title: responseMessage.value.title }
}

const handleSubmit = async (name, newRow) => {
  let { responseMessage } = await useAddRowData(
    name,
    newRow,
    concreteServiceId.value,
    tablesData,
    gradingServiceId.value,
    null,
    props.proposalServices.proposalsService.nodes
  )
  tablesData.value[name] = { data: [], savePayload: [] }
  proposalByIdQuery.refetch()
  toast.value = { showToast: responseMessage.value.showToast, title: responseMessage.value.title }
}

const updateData = async (name, row, isEditing, rowData) => {
  calculationsLoading.value = true
  await useUpdateRowData(
    name,
    row,
    isEditing,
    tablesData,
    props.proposalData.proposal.publicWorks,
    rowData
  )
  calculationsLoading.value = false
}

const handleCrewSubmit = async (crew) => {
  proposalAddCrew.mutate({
    input: { input: { proposalId: route.params.id, crewId: crew.id } },
  })
  showModal.value = false
  await proposalByIdQuery.refetch()
  selectedCrew.value = null
}
</script>
