<template>
  <div v-if="loading">
    <Loader />
    <TableSkelton />
    <TableSkelton />
    <TableSkelton />
    <TableSkelton />
  </div>
  <div v-else class="px-10 py-6">
    <div class="my-3 flex items-center justify-between">
      <h1 class="text-xl font-semibold text-gray-900">{{ props.title }}</h1>
    </div>
    <InfoBar
      v-if="sectionQuestions.length === 0"
      :message="`No scopes are available for ${props.title}. You can add a new scope`"
    />
    <draggable
      v-else
      class="dragArea list-group"
      :disabled="true"
      :list="sectionQuestions"
      :group="{ name: 'people', pull: 'clone', put: false }"
      @change="log"
      item-key="id"
    >
      <template #item="{ element, index }">
        <div class="list-group-item">
          <li class="border-b-2 flex items-center justify-between py-3 pl-3 pr-4 text-sm">
            <div>
              <input
                type="checkbox"
                :checked="element['showInProposal']"
                v-model="element['showInProposal']"
                class="h-4 w-4 mr-2 rounded border-gray-300 text-primary-600 focus:ring-indigo-500"
                :class="element['showInProposal'] && 'print:hidden'"
                @change="($event) => handleCheckboxChange($event, index, element)"
              />
              <span v-if="element['showInProposal'] && element.populatedTemplate">
                <input
                  type="number"
                  class="w-14 py-0 border-0 border-b border-gray-300 focus:border-indigo-600 focus:ring-0 sm:text-sm"
                  v-model="element.position"
                  @change="handlePositionInput(element, sectionQuestions)"
                />
                -
              </span>
              <span class="hidden print-show">{{ element.populatedTemplate }} </span>
              <span
                v-if="element['showInProposal']"
                v-for="(part, tindex) in element['questionParts']"
                :key="tindex"
              >
                <input
                  v-if="part.type === 'static'"
                  placeholder="Enter question"
                  type="text"
                  :value="part.text"
                  @input="handleTextWidth($event, element, part, sectionQuestions)"
                  @change="handleInput(element, part)"
                  :style="{
                    minWidth: '200px',
                    width: `${part.text.length * 8.5}px`,
                  }"
                  class="py-0 bg-primary-100 border-0 border-b border-gray-300 focus:border-indigo-600 focus:ring-0 sm:text-sm"
                />
                <PropertyFormInput
                  v-else
                  :property="part"
                  v-model="element['inputVariables'][part.variable]"
                  :handleTextWidth="handleTextWidth"
                  :handleInput="handleInput"
                  :data="{ element: element, part: part, sectionQuestions: sectionQuestions }"
                />
              </span>
              <span v-else v-for="(part, index) in formattedText(element)" :key="index">
                <template v-if="part.type === 'static'">{{ part.text }}</template>
                <PropertyFormInput
                  v-else
                  :property="part"
                  v-model="element['inputVariables'][part.variable]"
                  :handleTextWidth="handleTextWidth"
                  :handleInput="handleInput"
                  :data="{ element: element, part: part, sectionQuestions: sectionQuestions }"
                />
              </span>
            </div>
            <input
              ref="clone"
              type="hidden"
              :id="`element-${element.id}`"
              :value="element.template"
            />
          </li>
        </div>
      </template>
    </draggable>
    <div v-if="subsection.length !== 0" v-for="section in subSectionQuestions">
      <div class="mt-6 mb-2 flex items-center justify-between">
        <h1 class="text-l font-semibold text-gray-900">{{ section.name }}</h1>
      </div>
      <InfoBar
        v-if="section.questions.length === 0"
        :message="`No scopes are available for ${section.title}. You can add a new scope`"
      />
      <draggable
        v-else
        class="dragArea list-group"
        :disabled="true"
        :list="section.questions"
        :group="{ name: 'people', pull: 'clone', put: false }"
        @change="log"
        item-key="id"
      >
        <template #item="{ element, index }">
          <div class="list-group-item">
            <li class="border-b-2 flex items-center justify-between py-3 pl-3 pr-4 text-sm">
              <div>
                <input
                  type="checkbox"
                  :checked="element['showInProposal']"
                  class="h-4 w-4 mr-2 rounded border-gray-300 text-primary-600 focus:ring-indigo-500"
                  :class="element['showInProposal'] && 'print:hidden'"
                  v-model="element['showInProposal']"
                  @change="($event) => handleCheckboxChange($event, index, element, section)"
                />
                <span v-if="element['showInProposal'] && element.populatedTemplate">
                  <input
                    type="number"
                    class="w-14 py-0 border-0 border-b border-gray-300 focus:border-indigo-600 focus:ring-0 sm:text-sm"
                    v-model="element.position"
                    @change="handlePositionInput(element, section.questions)"
                  />
                  -
                </span>
                <span class="hidden print-show">{{ element.populatedTemplate }} </span>
                <span
                  v-if="element['showInProposal']"
                  v-for="(part, tindex) in element['questionParts']"
                  :key="tindex"
                >
                  <input
                    v-if="part.type === 'static'"
                    type="text"
                    placeholder="Enter question"
                    :value="part.text"
                    @input="handleTextWidth($event, element, part, section.questions)"
                    @change="handleInput(element, part)"
                    :style="{
                      minWidth: '200px',
                      width: `${part.text.length * 8.5}px`,
                    }"
                    class="py-0 bg-primary-100 border-0 border-b border-gray-300 focus:border-indigo-600 focus:ring-0 sm:text-sm"
                  />
                  <PropertyFormInput
                    v-else
                    :property="part"
                    v-model="element['inputVariables'][part.variable]"
                    :handleTextWidth="handleTextWidth"
                    :handleInput="handleInput"
                    :data="{ element: element, part: part, sectionQuestions: section.questions }"
                  />
                </span>
                <span v-else v-for="(part, index) in formattedText(element)" :key="index">
                  <template v-if="part.type === 'static'">{{ part.text }}</template>
                  <PropertyFormInput
                    v-else
                    :property="part"
                    v-model="element['inputVariables'][part.variable]"
                    :handleTextWidth="handleTextWidth"
                    :handleInput="handleInput"
                    :data="{ element: element, part: part, sectionQuestions: section.questions }"
                  />
                </span>
              </div>
              <input
                ref="clone"
                type="hidden"
                :id="`element-${element.id}`"
                :value="element.template"
              />
            </li>
          </div>
        </template>
      </draggable>
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue'
import draggable from 'vuedraggable'
import { useMutation } from '@vue/apollo-composable'

import InfoBar from '@/components/layout/InfoBar.vue'
import PropertyFormInput from '@/components/layout/WriteupInputs/PropertyFormInput.vue'
import Loader from '@/components/layout/Loader.vue'
import TableSkelton from '@/components/layout/TableSkelton.vue'
import { isValidNumberValue } from '@/utils/utility_methods'
import UPDATE_WRITEUP_QUESTION from '@/graphql/mutations/updateWriteupQuestion.gql'

const props = defineProps({
  title: {
    type: String,
    default: '',
  },
  questions: {
    type: Array,
    default: [],
  },
  populatedQuestions: {
    type: Array,
    default: [],
  },
  subsection: {
    type: Array,
    default: [],
  },
})

const emit = defineEmits([
  'updateData',
  'refetchData',
  'addWriteupQuestion',
  'addNewWriteupQuestion',
  'updateQuestions',
])

const sectionQuestions = ref([])
const subSectionQuestions = ref([])
const updateWriteupQuestion = useMutation(UPDATE_WRITEUP_QUESTION)
const loading = ref(false)

const updateSectionQuestions = () => {
  sectionQuestions.value = [
    ...transformQuestions([...props.populatedQuestions], true),
    ...transformQuestions(props.questions),
  ]
}

const updateSubSectionQuestions = () => {
  subSectionQuestions.value = props.subsection.map((element) => ({
    ...element,
    questions: [
      ...transformQuestions([...element.submittedQuestions], true),
      ...transformQuestions(element.questions),
    ],
  }))
}

const transformQuestions = (questions, isPopulated = false) =>
  JSON.parse(JSON.stringify(questions))
    ?.sort((a, b) => a.position - b.position)
    .map((question, index) => ({
      ...question,
      index,
      indexId: `${isPopulated ? 'populated' : 'question'}_${index}`,
      showInProposal: isPopulated,
      inputVariables: isPopulated ? convertArrayToObject(question.templateBoundProperties) : {},
      questionParts: formattedText(question),
    }))

const formattedText = (element) => {
  const regex = /\{\{(\w+)\}\}/g
  const parts = []

  let match
  let lastIndex = 0
  let uniqueIndex = 0
  while ((match = regex.exec(element.template)) !== null) {
    const variable = match[1]
    if (match.index > lastIndex) {
      parts.push({
        index: uniqueIndex++,
        type: 'static',
        text: element.template.substring(lastIndex, match.index),
      })
    }
    let variableType = element['templateBoundProperties'].find(
      (item) => item.variableName == variable
    )
    parts.push({
      index: uniqueIndex++,
      type:
        variableType && variableType.properties && variableType.properties.length
          ? variableType.properties[0].type
          : 'text',
      variable,
      defaultValue:
        variableType && variableType.properties && variableType.properties.length > 0
          ? variableType.properties[0].defaultValue
            ? variableType.properties[0].defaultValue.split(',')
            : ''
          : '',
    })
    lastIndex = regex.lastIndex
  }
  if (lastIndex < element.template.length) {
    parts.push({
      index: uniqueIndex++,
      type: 'static',
      text: element.template.substring(lastIndex),
    })
  }
  return parts
}

const log = async (dragElement) => {
  loading.value = true

  const questions = props.subsection.find(
    (item) => item.id == dragElement.moved.element.section?.id
  )
    ? props.subsection.find((item) => item.id == dragElement.moved.element.section?.id)
        ?.submittedQuestions
    : props.populatedQuestions
  if (dragElement.moved.element?.showInProposal && questions[dragElement.moved.newIndex]) {
    const newPosition = dragElement.moved.newIndex + 1
    const oldPosition = dragElement.moved.oldIndex + 1
    for (const row of questions) {
      if (row.id == dragElement.moved.element.id) {
        await updateWriteupQuestion.mutate({
          input: { input: { id: row.id, position: newPosition } },
        })
      } else if (row.position !== 1 && row.position <= newPosition && row.position > oldPosition) {
        await updateWriteupQuestion.mutate({
          input: { input: { id: row.id, position: row.position - 1 } },
        })
      } else if (row.position < oldPosition && row.position >= newPosition) {
        await updateWriteupQuestion.mutate({
          input: { input: { id: row.id, position: row.position + 1 } },
        })
      }
    }
  }
  emit('refetchData')
  loading.value = false
}

const handleCheckboxChange = (e, itemIndex, element, subSection) => {
  let newQuestion = null
  let targetQuestions = sectionQuestions.value
  if (subSection) {
    const subSectionIndex = subSectionQuestions.value.findIndex(
      (section) => section.id === subSection.id
    )
    if (subSectionIndex !== -1) {
      targetQuestions = subSectionQuestions.value[subSectionIndex]['questions']
    }
  }

  if (e.target.checked) {
    let duplicatesLength = targetQuestions.filter(
      (question) => question.id === element.id && question.showInProposal
    )?.length
    e.target.checked = false
    element.showInProposal = false
    newQuestion = {
      ...JSON.parse(JSON.stringify(element)),
      duplicateId: `dup-${element.id}-${Date.now()}`,
      showInProposal: true,
      inputVariables: filterTemplateBindings(element.templateBindings),
    }
    targetQuestions.splice(itemIndex + duplicatesLength, 0, newQuestion)
  } else {
    targetQuestions.splice(itemIndex, 1)
    element.inputVariables = {}
  }

  if (element.clearOnSelect) {
    element.questionParts[0].text = ''
  }
  emit('updateData', newQuestion ?? element, itemIndex)
  newQuestion = null
}

const handleTextWidth = (e, element, part, questions) => {
  let inputUpdated = false
  if (part.type === 'static') {
    element.questionParts[part.index]['text'] = e.target.value
    inputUpdated = true
  } else {
    element.propertiesEdited = true
    let inputValue = e.target.value
      .toString()
      .replace(/[^0-9.]/g, '')
      .replace(/(\..*)\./g, '$1')
    inputUpdated = element['inputVariables'][part.variable] !== e.target.value
    if (part.type !== 'select') {
      if (inputValue === '.' || /^[0-9]+\.$/.test(inputValue)) {
        e.target.value = inputValue
      } else if (isValidNumberValue(inputValue)) {
        element['inputVariables'][part.variable] = inputValue
        e.target.value = inputValue
      } else {
        e.target.value = ''
      }
    } else {
      element['inputVariables'][part.variable] = e.target.value
    }
  }
  if (element['populatedTemplate'] && inputUpdated) {
    element.edited = true
    emit(
      'updateQuestions',
      questions.filter((item) => item.edited)
    )
  } else {
    element.create = true
  }
}

const handleInput = (element, part) => {
  if (part.type === 'static') {
    element['template'] = element['questionParts']
      .map((item) => {
        if (item.type === 'static') {
          return item.text
        } else {
          return `{{${item.variable}}}`
        }
      })
      .join('')
  }
}

const convertArrayToObject = (array) => {
  const result = {}
  array.forEach((item) => {
    result[item.variableName] = item.value
  })
  return result
}

const filterTemplateBindings = (templateBindings) => {
  return Object.fromEntries(
    Object.entries(JSON.parse(templateBindings)).filter(([key, value]) => value !== null)
  )
}

const handlePositionInput = (element, questions) => {
  element.edited = true
  emit(
    'updateQuestions',
    questions.filter((item) => item.edited)
  )
}

watch(() => props.populatedQuestions, updateSectionQuestions, { deep: true, immediate: true })

watch(() => props.subsection, updateSubSectionQuestions, { deep: true, immediate: true })
</script>
