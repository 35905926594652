<template>
  <Loader v-if="loading" />
  <div>
    <div
      class="flex pl-1 pt-6 items-center hover:cursor-pointer"
      @click="router.push(`/estimations/${route.params.id}/writeup`)"
    >
      <ArrowLongLeftIcon class="h-5 w-5 mr-2" />
      <p class="text-sm hover:text-primary-700">Back to Proposal</p>
    </div>
    <div>
      <div class="sm:hidden">
        <label for="tabs" class="sr-only">Select a tab</label>
        <select
          id="tabs"
          name="tabs"
          class="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
        >
          <option v-for="tab in tabs" :key="tab.name" :selected="tab.current">
            {{ tab.name }}
          </option>
        </select>
      </div>
      <div class="hidden sm:block">
        <div class="border-b border-gray-200">
          <nav class="-mb-px flex space-x-8" aria-label="Tabs">
            <p
              v-for="tab in tabs"
              @click="handleTabChange(tab)"
              :key="tab.name"
              :class="[
                tab.current
                  ? 'border-indigo-500 text-primary-600'
                  : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                'group inline-flex items-center border-b-2 py-4 px-1 text-sm font-medium',
              ]"
              :aria-current="tab.current ? 'page' : undefined"
            >
              <span>{{ tab.name }}</span>
            </p>
          </nav>
        </div>
      </div>
    </div>
    <div class="flex flex-col">
      <div class="border rounded-lg border pb-6 border-gray-200">
        <div>
          <div v-if="selected.name == 'Client'">
            <div
              class="bg-primary-100 flex items-center border-b border-gray-200 justify-between px-7"
            >
              <h1 class="py-5 text-2xl font-semibold text-gray-900">Client Details</h1>
            </div>
            <div class="px-10 py-10 border-b">
              <div class="flex justify-center max-w-4xl mx-auto px-16">
                <div class="w-4/5">
                  <ClientForm
                    :clientData="proposalResult.proposal.client?.nodes[0]"
                    :states="statesQuery.items.value"
                    :dataLoading="statesQuery.loading.value"
                    @close-modal="clientModal = false"
                    @update-field="populateField"
                    @update-client="updateClientDetails"
                    @success-toast="
                      toast = { showToast: true, title: 'New Client created successfully!' }
                    "
                    @loadMoreStates="statesQuery.loadMore"
                    @filterStates="(input) => tableFilterFieldsHandler(input, statesQuery)"
                  />
                </div>
              </div>
            </div>
          </div>
          <div v-if="selected.name == 'Proposal'">
            <div
              class="bg-primary-100 flex items-center border-b border-gray-200 justify-between px-7"
            >
              <h1 class="py-5 text-2xl font-semibold text-gray-900">Project Details</h1>
            </div>
            <div class="px-10 py-6">
              <form @submit.prevent="onSubmit" class="space-y-4">
                <div class="grid grid-cols-2 items-center gap-4 max-w-4xl mx-auto px-16">
                  <div class="col-span-2 my-0.5">
                    <div flex items-start justify-start>
                      <BaseCheckbox label="Public work" v-model="publicWork" />
                    </div>
                  </div>
                  <div class="col-span-2 my-0.5">
                    <div flex items-start justify-start>
                      <BaseCheckbox label="Unit Pricing Proposal" v-model="unitPricingBid" />
                    </div>
                  </div>
                  <div class="w-80 col-span-1">
                    <BaseInput
                      name="project_name"
                      v-model="projectName"
                      @change="handleProjectNameChange"
                      type="text"
                      label="Project Name"
                      :error="errors.projectName"
                      required
                    />
                  </div>
                  <div class="w-80 col-span-1">
                    <BaseInput
                      name="name"
                      v-model="supervisorName"
                      @change="handleSupervisorNameChange"
                      type="text"
                      label="Supervisor Name"
                      :error="errors.supervisorName"
                      required
                    />
                  </div>
                  <div class="w-80 col-span-1">
                    <BaseInput
                      name="name"
                      type="tel"
                      v-model="supervisorPhone"
                      @change="handleSupervisorPhoneChange"
                      label="Supervisor Phone"
                      :error="errors.supervisorPhone"
                    />
                  </div>
                  <div class="w-80 col-span-1">
                    <BaseInput
                      name="name"
                      type="text"
                      v-model="supervisorEmail"
                      @change="handleSupervisorEmailChange"
                      label="Supervisor Email"
                      :error="errors.supervisorEmail"
                    />
                  </div>

                  <div class="w-80 col-span-1">
                    <BaseInput
                      name="address1"
                      v-model="address1"
                      @change="handleAddress1Change"
                      type="text"
                      label="Address"
                      :error="errors.address1"
                      required
                    />
                  </div>

                  <div class="w-80 col-span-1">
                    <BaseInput
                      name="city"
                      v-model="city"
                      type="text"
                      @change="handleCityChange"
                      label="City"
                      :error="errors.city"
                      required
                    />
                  </div>
                  <div class="col-span-1 w-80">
                    <BaseAutocomplete
                      label="Select State"
                      v-model="state"
                      :searchArray="statesQuery.items.value"
                      :fetchOnScroll="true"
                      :dataLoading="statesQuery.loading.value"
                      :required="true"
                      @loadMore="statesQuery.loadMore"
                      @filterData="(input) => tableFilterFieldsHandler(input, statesQuery)"
                    />
                  </div>

                  <div class="w-80 col-span-1">
                    <BaseInput
                      name="zipcode"
                      type="text"
                      v-model="zipcode"
                      @change="handleZipcodeChange"
                      label="Zip Code"
                      :error="errors.zipcode"
                      required
                    />
                  </div>
                  <div class="w-80 col-span-1">
                    <label class="block capitalize text-sm font-medium text-gray-700">Notes</label>
                    <textarea
                      class="w-full mt-1 block px-3 py-2 border rounded-md shadow-sm placeholder-gray-400 border-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      id="description"
                      v-model="description"
                      rows="2"
                      placeholder="Your message"
                    ></textarea>
                  </div>

                  <div class="pt-5 flex justify-start col-span-2">
                    <button
                      type="submit"
                      class="inline-flex justify-center rounded-md border border-transparent bg-success-500 px-4 py-2 text-sm font-medium text-slate-50 hover:bg-success-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Toast v-if="toast.showToast" :title="toast.title" />
</template>
<script setup>
import { ref, watch, computed } from 'vue'
import { useField, useForm } from 'vee-validate'
import { object, string, boolean } from 'yup'
import { useQuery } from '@vue/apollo-composable'
import { useMutation } from '@vue/apollo-composable'
import { useRoute, useRouter } from 'vue-router'
import { ArrowLongLeftIcon } from '@heroicons/vue/20/solid'

import Loader from '@/components/layout/Loader.vue'
import BaseAutocomplete from '@/components/layout/BaseAutocomplete.vue'
import BaseInput from '@/components/layout/BaseInput.vue'
import ClientForm from '@/components/layout/ClientForm.vue'
import BaseCheckbox from '@/components/layout/BaseCheckbox.vue'
import Toast from '@/components/layout/Toast.vue'
import { usePaginatedQuery } from '@/composables/usePaginatedQuery'

import GET_STATES from '@/graphql/queries/getStates.gql'
import UPDATE_PROPOSAL from '@/graphql/mutations/updateProposal.gql'
import UPDATE_MANAGER from '@/graphql/mutations/updateManager.gql'
import UPDATE_CLIENT from '@/graphql/mutations/updateClient.gql'
import UPDATE_ADDRESS from '@/graphql/mutations/updateAddress.gql'
import GET_PROPOSAL_BY_ID from '@/graphql/queries/getProposalById.gql'

const route = useRoute()
const router = useRouter()

const clientModal = ref(false)
const selectedClient = ref(null)
const isLoading = ref(false)
const toast = ref({ showToast: false, title: '' })
const proposalSubmitData = ref(null)
const tabs = ref([
  { name: 'Proposal', href: '#', current: true },
  { name: 'Client', href: '#', current: false },
])

const validationSchema = object({
  publicWork: boolean(),
  unitPricingBid: boolean(),
  projectName: string().required(),
  supervisorName: string().required(),
  supervisorPhone: string()
    .nullable()
    .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value)),
  supervisorEmail: string().email().nullable(true),
  address1: string().required(),
  city: string().required(),
  zipcode: string().required(),
  state: object().required(),
})

const selected = computed(() => tabs.value.filter((item) => item.current)[0])

const statesQuery = usePaginatedQuery(GET_STATES, 'states')

const { handleSubmit, errors } = useForm({
  validationSchema,
  initialValues: {
    publicWork: false,
    unitPricingBid: false,
  },
})

const updateProposal = useMutation(UPDATE_PROPOSAL)
const updateManager = useMutation(UPDATE_MANAGER)
const updateAddress = useMutation(UPDATE_ADDRESS)
const updateClient = useMutation(UPDATE_CLIENT)
const {
  result: proposalResult,
  loading: proposalDataLoading,
  refetch,
} = useQuery(GET_PROPOSAL_BY_ID, {
  id: route.params.id,
})

const { value: address, handleChange: handleAddressChange } = useField('address')
const { value: clientAddress } = useField('clientAddress')
const { value: publicWork } = useField('publicWork')
const { value: unitPricingBid } = useField('unitPricingBid')
const { value: projectName, handleChange: handleProjectNameChange } = useField('projectName')
const { value: supervisorName, handleChange: handleSupervisorNameChange } =
  useField('supervisorName')
const { value: supervisorPhone, handleChange: handleSupervisorPhoneChange } =
  useField('supervisorPhone')
const { value: supervisorEmail, handleChange: handleSupervisorEmailChange } =
  useField('supervisorEmail')
const { value: manager, handleChange: handleManagerChange } = useField('manager')
const { value: city, handleChange: handleCityChange } = useField('city')
const { value: address1, handleChange: handleAddress1Change } = useField('address1')
const { value: zipcode, handleChange: handleZipcodeChange } = useField('zipcode')
const { value: state, handleChange: handleStateChange } = useField('state')
const { value: description } = useField('description')

watch(proposalResult, (proposalData) => {
  if (proposalData) {
    projectName.value = proposalData.proposal.title
    publicWork.value = proposalData.proposal.publicWorks
    unitPricingBid.value = proposalData.proposal.unitPricingBid
    description.value = proposalData.proposal.description
    supervisorName.value = proposalData.proposal.supervisor.nodes[0].name
    supervisorPhone.value = proposalData.proposal.supervisor.nodes[0].phone
    supervisorEmail.value = proposalData.proposal.supervisor.nodes[0].email
    address1.value = proposalData.proposal.supervisor.nodes[0].address.address1
    city.value = proposalData.proposal.supervisor.nodes[0].address.city
    zipcode.value = proposalData.proposal.supervisor.nodes[0].address.zipcode
    state.value = { ...proposalData.proposal.supervisor.nodes[0].address.state }
    description.value = proposalData.proposal.description
  }
})

const handleTabChange = (selectedItem) => {
  tabs.value = tabs.value.map((item) =>
    item.name == selectedItem.name ? { ...item, current: true } : { ...item, current: false }
  )
}

function populateField(field, value) {
  if (field == 'clientDetails') {
    selectedClient.value = value
    clientAddress.value = value.address
  } else if (field == 'clientAddress') {
    clientAddress.value = value
  } else if (field == 'address') {
    address.value = value
  } else if (field == 'manager') {
    manager.value = value
  }
}

const onSubmit = handleSubmit(async (values) => {
  proposalSubmitData.value = values
  isLoading.value = true
  await updateAddress.mutate({
    input: {
      input: {
        id: proposalResult.value.proposal.supervisor.nodes[0].address.id,
        address1: values.address1,
        city: values.city,
        zipcode: values.zipcode,
        stateId: values.state.id,
      },
    },
  })

  await updateProposal.mutate({
    input: {
      input: {
        id: proposalResult.value.proposal.id,
        name: values.projectName,
        description: values.description,
        publicWorks: values.publicWork,
        unitPricingBid: values.unitPricingBid,
      },
    },
  })
  await updateManager.mutate({
    input: {
      input: {
        id: proposalResult.value.proposal.supervisor.nodes[0].id,
        name: values.supervisorName,
        email: values.supervisorEmail,
        phone: values.supervisorPhone,
      },
    },
  })
  toast.value = { showToast: true, title: 'Proposal updated Successfully!' }
  await refetch()
  isLoading.value = false
})

const loading = computed(() => {
  return isLoading.value || proposalDataLoading.value
})
const updateClientDetails = async (data) => {
  await updateAddress.mutate({
    input: {
      input: {
        id: proposalResult.value.proposal.client.nodes[0].address.id,
        address1: data.address,
        city: data.city,
        zipcode: data.zipcode,
        stateId: data.state.id,
      },
    },
  })
  await updateClient.mutate({
    input: {
      input: {
        id: proposalResult.value.proposal.client.nodes[0].id,
        name: data.name,
        email: data.email,
        phone: data.phone,
        companyName: data.companyName,
      },
    },
  })
  await refetch()
  toast.value = { showToast: true, title: 'Client updated Successfully!' }
}
</script>
