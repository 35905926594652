import { defineStore } from 'pinia'
import { ref } from 'vue'

import { apolloClient } from '@/helpers/apolloClient.js'
import { updateTemplateBoundProperties } from '@/utils/utility_methods'
import GET_WRITEUP_SECTIONS from '@/graphql/queries/getWriteupSections.gql'
import CREATE_WRITEUP from '@/graphql/mutations/createWriteup.gql'
import UPDATE_WRITEUP from '@/graphql/mutations/updateWriteup.gql'
import REMOVE_WRITEUP_QUESTION from '@/graphql/mutations/removeWriteupQuestion.gql'

export const useWriteupStore = defineStore('writeup', () => {
  const writeupData = ref([])
  const isDataLoading = ref(false)

  const fetchWriteupSections = async (proposalId) => {
    try {
      isDataLoading.value = true
      const { data } = await apolloClient.query({
        query: GET_WRITEUP_SECTIONS,
        variables: { proposalId: proposalId },
        fetchPolicy: 'network-only',
      })
      writeupData.value = data?.writeupSections.nodes ?? []
    } catch (error) {
      console.log(error)
    } finally {
      isDataLoading.value = false
    }
  }

  const createWriteupQuestions = async (payload, item) => {
    const section = findSection(writeupData.value, item.writeupSection?.id)
    try {
      const response = await apolloClient.mutate({
        mutation: CREATE_WRITEUP,
        variables: { input: { input: payload } },
      })
      const newQuestion = response.data.writeupCreate.submittedQuestion
      const updatedSubmittedQuestions = [
        ...(section.submittedQuestions || []),
        {
          ...newQuestion,
          templateBoundProperties: updateTemplateBoundProperties(
            item.templateBoundProperties,
            JSON.parse(newQuestion.templateBindings)
          ),
          section: item.writeupSection,
        },
      ]

      const updatedSection = {
        ...section,
        submittedQuestions: updatedSubmittedQuestions,
        questions: section.questions?.map((question) => ({
          ...question,
          writeupSection: {
            ...question.writeupSection,
            submittedQuestions: updatedSubmittedQuestions,
          },
        })),
      }
      writeupData.value = updateWriteupData(writeupData.value, updatedSection)
    } catch (error) {
      console.log(error)
    }
  }

  const removeWriteupQuestion = async (questions) => {
    if (!questions.length) return

    const questionIds = questions.map((q) => q.id)

    try {
      await apolloClient.mutate({
        mutation: REMOVE_WRITEUP_QUESTION,
        variables: { input: { ids: questionIds } }, // Send all question IDs at once
      })

      const updatedSections = new Map() // To avoid duplicate updates

      questions.forEach((question) => {
        const sectionId = question.writeupSection ? question.writeupSection.id : question.section.id
        if (!updatedSections.has(sectionId)) {
          const section = findSection(writeupData.value, sectionId)

          const updatedSection = {
            ...section,
            submittedQuestions: section.submittedQuestions
              .filter((q) => !questionIds.includes(q.id)) // Remove questions
              .map((q, index) => ({ ...q, position: index + 1 })), // Reposition

            questions: section.questions.map((q) => ({
              ...q,
              writeupSection: {
                ...q.writeupSection,
                submittedQuestions: section.submittedQuestions
                  .filter((submittedQ) => !questionIds.includes(submittedQ.id))
                  .map((submittedQ, index) => ({ ...submittedQ, position: index + 1 })),
              },
            })),
          }

          updatedSections.set(sectionId, updatedSection)
        }
      })

      updatedSections.forEach((updatedSection) => {
        writeupData.value = updateWriteupData(writeupData.value, updatedSection)
      })
    } catch (error) {
      console.error('Error removing questions:', error)
    }
  }

  const updateWriteupQuestions = async (question, payload) => {
    const section = await findSection(writeupData.value, question.section.id)

    try {
      const response = await apolloClient.mutate({
        mutation: UPDATE_WRITEUP,
        variables: { input: { input: payload } },
      })

      const updatedQuestion = response.data.writeupUpdate.updatedQuestion
      const existingQuestions = section.submittedQuestions || []
      const questionIndex = existingQuestions.findIndex((q) => q.id === question.id)

      if (questionIndex === -1) {
        console.error(`Question with ID "${question.id}" not found.`)
        return
      }

      const updatedQuestionObj = {
        ...existingQuestions[questionIndex],
        ...updatedQuestion,
        templateBoundProperties: updateTemplateBoundProperties(
          question.templateBoundProperties,
          JSON.parse(updatedQuestion.templateBindings)
        ),
      }

      const updatedSection = updateWriteupData(writeupData.value, {
        ...section,
        submittedQuestions: [
          ...existingQuestions.slice(0, questionIndex),
          updatedQuestionObj,
          ...existingQuestions.slice(questionIndex + 1),
        ],
        questions: section.questions.map((q) => ({
          ...q,
          writeupSection: {
            ...q.writeupSection,
            submittedQuestions: [
              ...existingQuestions.slice(0, questionIndex),
              updatedQuestionObj,
              ...existingQuestions.slice(questionIndex + 1),
            ],
          },
        })),
      })

      writeupData.value = updatedSection
    } catch (error) {
      console.log('Error updating question:', error)
    }
  }

  const findSection = (sections, id) => {
    for (const section of sections) {
      if (section.id === id) {
        return section
      }
      if (section.children && section.children.length > 0) {
        const found = findSection(section.children, id)
        if (found) return found
      }
    }
    return null
  }

  const updateWriteupData = (sections, updatedSection) => {
    return sections.map((sec) => {
      if (sec.id === updatedSection.id) {
        return updatedSection
      }
      if (sec.children && sec.children.length > 0) {
        return {
          ...sec,
          children: updateWriteupData(sec.children, updatedSection),
        }
      }
      return sec
    })
  }

  return {
    writeupData,
    isDataLoading,
    fetchWriteupSections,
    createWriteupQuestions,
    updateWriteupQuestions,
    removeWriteupQuestion,
  }
})
