<template>
  <Loader v-if="proposalOptions.loading.value" />
  <div class="min-h-screen">
    <div class="my-10 mx-4 sm:mx-auto sm:w-full sm:max-w-md text-center">
      <img class="mx-auto h-12 w-auto" :src="logo" alt="bidproz" />
      <h2 v-show="isValidToken" class="mt-6 text-xl sm:text-2xl font-bold text-gray-900">
        Proposal Details
      </h2>
    </div>
    <div class="my-5 px-6 sm:px-8 border-gray-200 w-full sm:w-2/3 mx-auto">
      <div v-if="isValidToken" class="page pb-12">
        <div class="bg-gray-200 p-6 sm:p-10 rounded-lg">
          <h3 class="text-lg font-semibold text-gray-900">Proposal Options</h3>
          <div v-if="proposalOptions.result.value?.proposalBasedOptions.length">
            <p class="text-sm py-2">Select the options that you want to add in the proposal</p>
            <dl class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 md:grid-cols-3">
              <div
                v-for="option in proposalOptions.result.value?.proposalBasedOptions"
                :key="option.id"
                class="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6"
              >
                <dt class="flex items-center text-sm font-medium text-gray-500">
                  <input
                    :id="option.name"
                    type="checkbox"
                    :checked="option.included"
                    @click="handleOptionSelect($event, option)"
                    class="h-4 w-4 rounded border-gray-300 text-primary-600 focus:ring-indigo-500"
                  />
                  <span class="pl-3 text-lg">{{ option.name }}</span>
                </dt>
                <dd class="mt-1 text-xl font-semibold tracking-tight text-gray-900">
                  ${{ option.estimatedCost }}
                </dd>
              </div>
            </dl>
          </div>
          <div v-else class="py-10 flex justify-center">
            <p class="text-base font-semibold text-gray-900">No options added in Proposal</p>
          </div>
        </div>

        <div class="flex justify-center mt-6 sm:mt-10 pb-10">
          <button
            @click="generatePDF"
            type="button"
            class="inline-flex items-center rounded-md bg-primary-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            Download Proposal
          </button>
        </div>
      </div>
      <div v-else class="text-center px-4">
        <p class="text-sm sm:text-base">
          <span class="font-bold">Unauthorized Access</span> - You do not have permission to view
          this page. Please log in with the correct credentials or contact support if you believe
          this is a mistake.
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { useQuery, useMutation } from '@vue/apollo-composable'
import axios from 'axios'

import logo from '@/assets/logo.png'
import Loader from '@/components/layout/Loader.vue'
import PROPOSAL_OPTIONS from '@/graphql/queries/getProposalOptions.gql'
import UPDATE_OPTION from '@/graphql/mutations/updatePublicOption.gql'

const route = useRoute()
const isValidToken = ref(false)

const updateOption = useMutation(UPDATE_OPTION)
const proposalOptions = useQuery(PROPOSAL_OPTIONS, {
  id: route.params.id,
})

onMounted(async () => {
  const response = await axios.get(
    `/proposals/${route.params.id}/validate_token?token=${route.query.token}`
  )
  isValidToken.value = response.data.valid
})

const handleOptionSelect = async (e, option) => {
  return await updateOption.mutate({
    input: {
      id: option.id,
      included: e.target.checked,
    },
  })
}

const generatePDF = () => {
  window.open(`/reports/${route.params.id}.pdf?token=${route.query.token}`, '_blank')
}
</script>
